import * as React from 'react';
import { css } from '@emotion/core';


const styles = {
  root:         css`
    width: 100%;
    height: 100%;
    min-height: 390px;
    position: relative;
  `,
  mapContainer: css`
    position: absolute;
    width: 100%;
    height: 100%;
  `,
  map: css`
    width: 100%;
    height: 100%;
    border: none;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, .5);
  `
};


type Props = {
  className?: string;
};


/**
 * MapView: a component that
 *
 * PureComponent: true
 * Redux Connected: false
 */
const MapView: React.FC<Props> = props => (
  <div css={styles.root} className={props.className}>
    <div css={styles.mapContainer}>
      <iframe
        title='Google Maps Location'
        src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3057.4298608357785!2d-105.13091768432788!3d39.97649809021893!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x876bf35f27fe5291%3A0x397501588dc7d7d6!2sThe+Little+Groomer!5e0!3m2!1sen!2sus!4v1471574616181'
        css={styles.map}
      />
    </div>
  </div>
);

export default MapView;
