import React, { useState, useCallback } from 'react';
import iscc from '../assets/images/memberships/ISCC.svg';
import ncgia from '../assets/images/memberships/NCGIA.png';
import ndgaa from '../assets/images/memberships/NDGAA.svg';
import { css } from '@emotion/core';
import Membership from './Membership';
import { onMobileDevice } from '../layout/mediaQueries';


const isccLink  = 'https://www.iscceducation.com';
const ndgaaLink = 'https://www.nationaldoggroomers.com';
const ncgiaLink = 'https://nationalcatgroomers.com';

const isccDesc = [
  'The purpose of ISCC is to provide networking and educational programs. To this end, ISCC implements a '
  + 'comprehensive, multi-level certification program to establish acceptable and recognizable standards of pet '
  + 'styling excellence. ISCC also provides voluntary programs whereby pet styling expertise may be visibly '
  + 'demonstrated, measured and verified.',
];

const ndgaaDesc = [
  'In the pursuit of grooming excellence, this professional membership association of persons engaged in the grooming '
  + 'and care of dogs offers a curriculum of activities including educational workshops, certification testing, '
  + 'conduction of meetings, seminars, trade shows and grooming competitions.',
];
const ncgiaDesc = [
  'The National Cat Groomers Institute was formed in May 2007 for the purpose of setting and maintaining cat grooming '
  + 'standards, providing real-life training, and awarding certification to those that wish to take certification exams.',
  'The NCGI is the only feline-specific groomers’ association to offer such extensive training and certification to'
  + ' people around the globe, whether they are already working within the pet grooming industry or they are simply'
  + ' looking for a new career.',
];

const styles = {
  root:          css`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-around;
    
    ${onMobileDevice} { 
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  `,
  membershipMiddle: css`
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 25px;

    ${onMobileDevice} {
      width: 90%;
      height: auto;
      flex-direction: column;
      margin-left: auto;
      margin-right: auto;
    }
  `,
  membershipSide:    css`
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 25px;

    ${onMobileDevice} {
      width: 90%;
      height: auto;
      flex-direction: column;
      margin-left: auto;
      margin-right: auto;
    }
  `,
};


type Props = {
  className?: string;
}

const Memberships: React.FC<Props> = props => (
  <div css={styles.root} className={props.className}>
    <Membership
      title='International Society of Canine Cosmetology'
      imgSrc={iscc}
      link={isccLink}
      paragraphs={isccDesc}
      css={styles.membershipSide}
    />
    <Membership
      title='National Cat Groomers Institute'
      imgSrc={ncgia}
      link={ncgiaLink}
      paragraphs={ncgiaDesc}
      css={styles.membershipMiddle}
    />
    <Membership
      title='National Dog Groomers Association of America'
      imgSrc={ndgaa}
      link={ndgaaLink}
      paragraphs={ndgaaDesc}
      css={styles.membershipSide}
    />
  </div>
);

export default Memberships;

