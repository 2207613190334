const ROOT = '';

export const Routes = {
  Contact:  `${ROOT}/contact`,
  About:    `${ROOT}/about`,
  Awards:   `${ROOT}/awards`,
  Stylists: `${ROOT}/stylists`,
  Home:     ROOT,
  // Retail: `${ROOT}/retail`,
};
