import { useMount } from './useMount';


export const useKeyListener = (
  onKeyUp: (event: KeyboardEvent) => void,
): void => useMount(
  () => {
    window.addEventListener('keyup', onKeyUp);

    return () => {
      window.removeEventListener('keyup', onKeyUp);
    };
  },
);
