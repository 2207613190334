import * as React from 'react';
import Alert from '../notifications/Alert';
import ImageCarousel from './ImageCarousel';
import { css } from '@emotion/core';
import Memberships from './Memberships';


const COVID = {
  title: 'We are open!',
  body:  'Due to COVID-19, please avoid more than one person in the .',
};


const styles = {
  root:     css`
  `,
  alert:    css`
    display: block;
    margin-bottom: 25px;
  `,
  carousel: css`
    margin-bottom: 50px;
    margin-left: auto;
    margin-right: auto;
    width: 650px;

    @media (max-width: 655px) {
      width: 95%;
    }
  `,
  hoursTxt: css`
    display: block;
    margin-bottom: 25px;
    margin-left: auto;
    margin-right: auto;
    font-size: 25px;
    text-align: center;
  `,
};


type Props = {
  className?: string;
};


/**
 * HomePage: a component that
 *
 * PureComponent: true
 * Redux Connected: false
 */
const HomePage: React.FC<Props> = props => (
  <div css={styles.root} className={props.className}>
    <span css={styles.hoursTxt}>Hours: 7:00am - 5:00pm; Monday - Friday</span>
    <ImageCarousel css={styles.carousel} />
    <Memberships />
  </div>
);

export default HomePage;
