import * as React from 'react';
import facebook from '../../assets/images/socialMedia/facebook.svg';
import yelp from '../../assets/images/socialMedia/yelp.svg';
import { css } from '@emotion/core';


const IMG_SIZE_PX   = 32;
const IMG_MARGIN_PX = 15;

const SOCIAL_MEDIA = [
  {
    name: 'Facebook',
    img:  facebook,
    link: 'https://www.facebook.com/The-Little-Groomer-207935712558651/',
  },
  {
    name: 'Yelp',
    img:  yelp,
    link: 'http://www.yelp.com/biz/the-little-groomer-louisville',
  },
];


const styles = {
  root: css`
    display: flex;
    flex-direction: row;
    width: ${SOCIAL_MEDIA.length * IMG_SIZE_PX + (SOCIAL_MEDIA.length - 1) * IMG_MARGIN_PX}px;
    height: ${IMG_SIZE_PX}px;
    
    & > a { 
      margin-right: ${IMG_MARGIN_PX}px;
    }
    
    & > a:last-child { 
      margin-right: 0;
    }
  `,
  img:  css`
    width: ${IMG_SIZE_PX}px;
    height: ${IMG_SIZE_PX}px;
  `,
};


type Props = {
  className?: string;
};


/**
 * SocialMediaButtons: a component that renders image buttons that link out to social media sites
 *
 * PureComponent: true
 * Redux Connected: false
 */
const SocialMediaButtons: React.FC<Props> = props => (
  <div css={styles.root} className={props.className}>
    {
      SOCIAL_MEDIA.map(
        media => (
          <a key={media.name} href={media.link} title={media.name} target='_blank' rel='noopener noreferrer'>
            <img src={media.img} alt={media.name} css={styles.img} />
          </a>
        ),
      )
    }
  </div>
);

export default SocialMediaButtons;
