import * as React from 'react';
import logo from '../assets/images/logo.svg';
import { css } from '@emotion/core';


const styles = {
  root:        css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 22px;
  `,
  logo:        css`
    width: 200px;
    height: 200px;
  `,
  phone:       css`
    text-decoration: none;
    color: black;
    font-weight: bold;
    font-size: 26px;
    margin-bottom: 10px;
  `,
  addressRoot: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-style: normal;
    margin-bottom: 10px;
  `,
  hours:       css`
    font-weight: bold;
    margin-bottom: 10px;
  `,
};


type Props = {
  className?: string;
};


/**
 * ContactDetails: a component that
 *
 * PureComponent: true
 * Redux Connected: false
 */
const ContactDetails: React.FC<Props> = props => (
  <div css={styles.root} className={props.className}>
    <img css={styles.logo} src={logo} alt='TLG Logo' />
    <a href='tel:303-666-0545' css={styles.phone}>(303) 666-0545</a>
    <address css={styles.addressRoot}>
      <span>1130 Pine St. Suite A</span>
      <span>Louisville, Colorado 80027</span>
    </address>
    <span css={styles.hours}>7:00am - 5:00pm; Monday - Friday</span>
  </div>
);

export default ContactDetails;
