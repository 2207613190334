import { css } from '@emotion/core';


export const PAGE_CONTENT = css`
  padding: 30px;

  @media only screen and (max-width: 700px) {
    padding: 5px;
  }
  
  @media only screen and (min-width: 2000px) {
    width: 2000px;
    margin-left: auto;
    margin-right: auto;
  }
`;
