import * as React from 'react';
import relaxingDog from '../assets/images/panache_relaxed.jpeg';
import { css } from '@emotion/core';


const styles = {
  root:          css`
    display: flex;
    flex-direction: row;
    margin-bottom: auto;
    font-size: 18px;

    @media (max-width: 1050px) {
      flex-direction: column-reverse;
      align-items: center;
    }
  `,
  relaxedDogImg: css`
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, .5);
    margin-left: 25px;
    width: 25%;
    height: 33.33%;

    @media (max-width: 1050px) {
      width: 90%;
      height: auto;
      margin: 15px auto 25px;
    }
  `,
};


type Props = {
  className?: string;
};


/**
 * AboutPage: a component that
 *
 * PureComponent: true
 * Redux Connected: false
 */
const AboutPage: React.FC<Props> = props => (
  <div css={styles.root} className={props.className}>
    <div>
      <h1><strong>Philosophy</strong></h1>
      <p>
        We invest the time it takes to treat each pet with dignity and respect.
      </p>
      <p>
        All pets deserve to have a pleasant, low-stress experience that suits them individually.
      </p>
      <p>
        We like to work with the pet owners so they may better understand their pet’s specific grooming needs. We can
        then
        tailor a plan to meet each pet owner’s expectations and budget.
      </p>
      <p>
        Our grooming prices are based on a hands-on labor charge. We charge only for the time spent working with your
        pet.
      </p>
      <p>
        Relaxation time between grooming stages is not figured into pricing; most pets benefit from having a short break
        during the grooming process.
      </p>
      <p>
        Please call for more information or drop by with your pet for a personal consultation.
      </p>
    </div>
    <img src={relaxingDog} alt='Relaxing Dog' css={styles.relaxedDogImg} />
  </div>
);

export default AboutPage;
