import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { css } from '@emotion/core';
import { motion } from 'framer-motion';
import { useKeyListener } from '../hooks/useKeyListener';
import { onMobileDevice, onPhone } from './mediaQueries';
import closeImg from '../assets/images/icons/close.svg';


const styles = {
  modalBg:            css`
    position: fixed;
    z-index: 100;

    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: rgba(0, 0, 0, 0.8);
    cursor: pointer;
    opacity: 0;
  `,
  modal:              css`
    cursor: default;
    overflow-x: hidden;
    overflow-y: auto;
    border-radius: 10px;
    background-color: #FEFEFE;
    opacity: 0;

    max-height: calc(100vh - 32px);
    max-width: calc(100vw - 32px);
    min-width: 550px;

    transition: all .5s ease;

    ${onMobileDevice} {
      min-width: 320px;
    }

    ${onPhone} {
      max-width: 100vw;
      max-height: 100vh;
      min-width: 0;
      min-height: 0;
      width: 100vw;
      height: 100vh;
    }
  `,
  modalHeaderRoot:    css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  `,
  modalHeaderExitBtn: css`
    background-color: transparent;
    border: none;
    
  `,
  modalHeaderExitImg: css`
    width: 35px;
    height: 35px;
    padding: 10px 5px;
    
    &:hover {
      opacity: .5;
      cursor: pointer;
    }
  `,
};


type Props = {
  onDismiss?: () => void,

  children: React.ReactNode | React.ReactNode[];
  className?: string;
};


/**
 * Modal: a component that renders its children as a modal with a dark background and intuitive close functionality.
 *
 * PureComponent: true
 * Redux Connected: false
 */
const Modal: React.FC<Props> = props => {
  const onClickToDismissModal = React.useCallback(() => props.onDismiss?.(), [props.onDismiss]);

  const onKeyUp = React.useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        props.onDismiss?.();
      }
    },
    [props.onDismiss],
  );

  const stopPropagation = React.useCallback(
    (event: React.MouseEvent) => event && event.stopPropagation(),
    [],
  );

  useKeyListener(onKeyUp);

  return ReactDOM.createPortal(
    <motion.div
      key='modal-bg'
      aria-modal='true'
      onClick={onClickToDismissModal}
      css={styles.modalBg}
      animate={{ opacity: [0, 1] }}
      transition={{ duration: .4 }}
    >
      <motion.div
        key='modal-content'
        onClick={stopPropagation}
        animate={{ scale: [.9, 1], opacity: [0, 1] }}
        transition={{ duration: .25 }}
        css={styles.modal}
        className={props.className}
      >
        <div css={styles.modalHeaderRoot}>
          <button onClick={onClickToDismissModal} css={styles.modalHeaderExitBtn}>
            <img src={closeImg} alt='Close' css={styles.modalHeaderExitImg} />
          </button>
        </div>
        {
          props.children
        }
      </motion.div>
    </motion.div>,
    document.body,
  );
};

export default Modal;
