import * as React from 'react';
import { css } from '@emotion/core';
import MapView from './MapView';
import ContactDetails from './ContactDetails';


const styles = {
  root: css`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap-reverse;
  `,
  leftSide: css`
    flex-grow: 1;
    flex-basis: 440px;
  `,
  rightSide: css`
    flex-grow: 1;
    flex-basis: 440px;
  `,
};


type Props = {
  className?: string;
};


/**
 * ContactPage: a component that
 *
 * PureComponent: true
 * Redux Connected: false
 */
const ContactPage: React.FC<Props> = props => (
  <div css={styles.root} className={props.className}>
    <MapView css={styles.leftSide}/>
    <ContactDetails css={styles.rightSide}/>
  </div>
);

export default ContactPage;
