import * as React from 'react';
import { Link } from 'react-router-dom';
import { Routes } from '../../routes';
import { css } from '@emotion/core';


const styles = {
  root:     css`
    margin: 0;
    padding: 0;
    max-width: 100vw;
    overflow-x: auto;
  `,
  listRoot: css`
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    
    & > li { 
      list-style-type: none;
      list-style-image: none;
      padding: 0;
      margin: 0;
    }
  `,
  link:     css`
    box-sizing: border-box;
    display: block;
    width: 80px;
    line-height: 22px;
    margin: auto;
    color: #f8f5f0;
    text-decoration: none;
    transition: all .25s ease;
    text-align: center;
    border-bottom: 2px solid transparent;
    user-select: none;
    
    &:hover { 
      cursor: pointer;
      border-bottom: 2px solid #f8f5f0;
      opacity: .8;
    }
  `,
  selected: css`
    font-weight: bold;
  `,
};


type Props = {
  className?: string;
};


/**
 * MainNav: a component that renders the links to the various pages in the app
 *
 * PureComponent: true
 * Redux Connected: false
 */
const MainNav: React.FC<Props> = props => {
  // TODO: style current route
  const currentRoute = window.location.href.split('/')[3];

  return (
    <nav css={styles.root} className={props.className}>
      <ul css={styles.listRoot}>
        <li>
          <Link to={Routes.Home} css={[styles.link, !currentRoute && styles.selected]}>Home</Link>
        </li>
        <li>
          <Link to={Routes.Stylists} css={[styles.link]}>Stylists</Link>
        </li>
        <li>
          <Link to={Routes.Awards} css={[styles.link]}>Awards</Link>
        </li>
        <li>
          <Link to={Routes.About} css={[styles.link]}>About</Link>
        </li>
        <li>
          <Link to={Routes.Contact} css={[styles.link]}>Contact</Link>
        </li>
      </ul>
    </nav>
  );
};

export default MainNav;
