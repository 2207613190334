import * as React from 'react';
import teresaImg from '../assets/images/groomers/teresa.jpg';
import { css } from '@emotion/core';


const stylists = [
  {
    img:  teresaImg,
    name: 'Teresa Lask',
    desc: 'Teresa began in the grooming industry in 1985, and opened The Little Groomer in 1992. Over the years '
          + 'she has enjoyed continuing her education to reach the status of National Certified Master Groomer through '
          + 'the National Dog Groomers Association of America, as well as a Petcare Dermatech Specialist through the '
          + 'International Society of Canine Cosmetologists. A member of the National Cat Groomers Institute of America, '
          + 'Teresa\'s love of animals is not limited to dogs, but also extends to cats, horses and more. She has enjoyed '
          + 'serving the Louisville/Lafayette areas and looks forward to many more years in this beautiful community.',
  },
];


const styles = {
  root:    css`
  `,
  stylist: {
    root: css`
      margin-bottom: 15px;
    `,
    name:        css`
      font-weight: bold;
      margin-top: 0;
    `,
    contentRoot: css`
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    `,
    img:         css`
      width: 236px;
      flex-basis: 236px;
      height: 308px;
      margin-right: 20px;
    `,
    desc:        css`
      font-size: 20px;
      line-height: 32px;
      flex-basis: 450px;
      flex-grow: 1;
      margin-top: 0;
      margin-bottom: 0;
    `,
  },
};


type Props = {
  className?: string;
};


/**
 * StylistsPage: a component that
 *
 * PureComponent: true
 * Redux Connected: false
 */
const StylistsPage: React.FC<Props> = props => (
  <div className={props.className}>
    {
      stylists.map(
        stylist => (
          <div key={stylist.name}>
            <h1 css={styles.stylist.name}>{stylist.name}</h1>
            <div css={styles.stylist.contentRoot}>
              <img src={stylist.img} alt={stylist.name} css={styles.stylist.img} />
              <p css={styles.stylist.desc}>{stylist.desc}</p>
            </div>
          </div>
        ),
      )
    }
  </div>
);

export default StylistsPage;
