import { css } from '@emotion/core';


export const BREAKPOINTS_PX = {
  MOBILE_SMALL:  320,
  MOBILE_MEDIUM: 500,
  MOBILE:        768,
};

const breakpoints = [
  BREAKPOINTS_PX.MOBILE_SMALL,
  BREAKPOINTS_PX.MOBILE_MEDIUM,
  BREAKPOINTS_PX.MOBILE,
];

export const mq = breakpoints.map(
  bp => `@media only screen and (max-width: ${bp}px)`,
);

export const onPhone        = mq[0];
export const onMobileMedium = mq[1];
export const onMobileDevice = mq[2];


export const HIDE_ON_PHONE = css`
  @media only screen and (max-width: ${BREAKPOINTS_PX.MOBILE_SMALL}px) {
    display: none;
  }
`;

export const HIDE_ON_MOBILE = css`
  @media only screen and (max-width: ${BREAKPOINTS_PX.MOBILE}px) {
    display: none;
  }
`;

export const HIDE_ON_MOBILE_MED = css`
  @media only screen and (max-width: ${BREAKPOINTS_PX.MOBILE_MEDIUM}px) {
    display: none;
  }
`;

export const SHOW_ON_PHONE = css`
  @media only screen and (min-width: ${BREAKPOINTS_PX.MOBILE_SMALL + 1}px) {
    display: none;
  }
`;

export const SHOW_ON_MOBILE = css`
  @media only screen and (min-width: ${BREAKPOINTS_PX.MOBILE + 1}px) {
    display: none;
  }
`;
