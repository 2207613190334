import * as React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import zero from '../assets/images/salon.jpg';
import one from '../assets/images/carousel/one.jpg';
import two from '../assets/images/carousel/two.jpg';
import three from '../assets/images/carousel/three.jpg';
import four from '../assets/images/carousel/four.jpg';
import five from '../assets/images/carousel/black-kitty-before-after.jpg';
import six from '../assets/images/carousel/foot-before-after.jpg';


type Props = {
  className?: string;
};


/**
 * ImageCarousel: a component that
 *
 * PureComponent: true
 * Redux Connected: false
 */
const ImageCarousel: React.FC<Props> = props => (
  <div className={props.className}>
    <Carousel
      emulateTouch={true}
      autoPlay={true}
      showStatus={false}
      showThumbs={false}
      infiniteLoop={true}
      width={'100%'}
    >
      <div>
        <img src={zero} style={{ width: '550px' }} alt='Shop' />
      </div>
      <div>
        <img src={one} style={{ width: '300px' }} alt='Poodle' />
      </div>
      <div>
        <img src={two} style={{ width: '300px' }} alt='Cat next to mountain of hair' />
      </div>
      <div>
        <img src={three} style={{ width: '300px' }} alt='Shop Inside' />
      </div>
      <div>
        <img src={four} style={{ width: '300px' }} alt='Cute wheaton' />
      </div>
      <div>
        <img src={five} style={{ width: '550px' }} alt='Black cat before/after' />
      </div>
      <div>
        <img src={six} style={{ width: '550px' }} alt='Fuzzy foot before/after' />
      </div>
    </Carousel>
  </div>
);

export default ImageCarousel;
