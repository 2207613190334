import * as React from 'react';
import MainNav from './MainNav';
import SocialMediaButtons from './SocialMediaButtons';
import LogoHomeButton from './LogoHomeButton';
import { css } from '@emotion/core';
import { PAGE_CONTENT } from '../../globalStyles';


const styles = {
  root:       css`
    background-color: #2a2a26;
  `,
  headerContent: css`
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 0;
  `,
  socialBtns: css`
    position: absolute;
    top: 5px;
    right: 5px;
  `,
  logoBtn:    css`
    width: 240px;
    align-self: center;
    margin-top: 30px;
  `,
  mainNav:    css`
    margin-bottom: 10px;
  `,
};


type Props = {
  className?: string;
};


/**
 * PageHeader: a component that
 *
 * PureComponent: true
 * Redux Connected: false
 */
const PageHeader: React.FC<Props> = props => (
  <section css={styles.root} className={props.className}>
    <div css={[PAGE_CONTENT, styles.headerContent]}>
      <SocialMediaButtons css={styles.socialBtns} />
      <LogoHomeButton css={styles.logoBtn} />
      <MainNav css={styles.mainNav} />
    </div>
  </section>
);

export default PageHeader;
