import * as React from 'react';
import ncmg from '../assets/images/ncmg.png';
import cmfg from '../assets/images/cmfg.png';
import panache from '../assets/images/panache.jpg';
import dermatech from '../assets/images/dermatech.png';
import { css } from '@emotion/core';


const styles = {
  root:     css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
  `,
  imgStyle: css`
    width: 45%;
    height: auto;
    margin: 25px;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, .5);

    @media (max-width: 1000px) {
      width: 80%;
      margin-left: auto;
      margin-right: auto;
    }

    @media (max-width: 575px) {
      width: calc(100vw - 25px);
      margin: 10px;
    }
  `,
};


type Props = {
  className?: string;
};


/**
 * AwardsPage: a component that
 *
 * PureComponent: true
 * Redux Connected: false
 */
const AwardsPage: React.FC<Props> = props => (
  <div className={props.className}>
    <img src={cmfg} css={styles.imgStyle} alt='CMFG Certificate' />
    <img src={ncmg} css={styles.imgStyle} alt='NCMG Certificate' />
    <img src={panache} css={styles.imgStyle} alt='Finished Poodle' />
    <img src={dermatech} css={styles.imgStyle} alt='Dermatech Certificate' />
  </div>
);

export default AwardsPage;
