import * as React from 'react';
import { useState, useCallback } from 'react';
import Modal from '../layout/Modal';
import { css } from '@emotion/core';


const styles = {
  root:  css`
    background-color: transparent;
    border: none;
  `,
  img:   css`
    width: 100%;
    transition: opacity .25s ease;
    &:hover {
      opacity: .5;
      cursor: pointer;
    }
  `,
  modal: {
    root:          css`
      max-width: 600px;
    `,
    content: css`
      padding-left: 25px;
      padding-right: 25px;
      padding-bottom: 25px;
    `,
    img: css`
      width: 250px;
      height: auto;
      display: block;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 25px;
    `,
    title: css`
      font-size: 26px;
      margin-top: 0;
    `,
    textBody: css`
      display: block;
      font-size: 18px;
      margin-bottom: 25px;
    `,
    learnMoreLink: css`
      transition: opacity .25s ease;
      display: block;
      width:120px;
      margin-left: auto;
      margin-right: auto;
      text-decoration: none;
      font-weight: bold;
      font-size: 18px;
      text-align: center;

      &:hover {
        opacity: .5;
        cursor: pointer;
      }
    `,
  },
};


type Props = {
  imgSrc: string;
  title: string;
  paragraphs: string[];
  link: string;
  className?: string;
};


/**
 * Membership: a component that
 *
 * PureComponent: true
 * Redux Connected: false
 */
const Membership: React.FC<Props> = props => {
  const [showModal, setShowModal] = useState<boolean>(false);

  const onClickMembership = useCallback(() => setShowModal(true), [setShowModal]);
  const onDismissModal    = useCallback(() => setShowModal(false), [setShowModal]);

  return <>
    <button onClick={onClickMembership} css={styles.root} className={props.className}>
      <img src={props.imgSrc} alt={props.title} title={props.title} css={styles.img}/>
    </button>
    {
      showModal && (
        <Modal onDismiss={onDismissModal} css={styles.modal.root}>
          <div css={styles.modal.content}>
            <img src={props.imgSrc} alt={props.title} title={props.title} css={styles.modal.img}/>
            <h1 css={styles.modal.title}>{props.title}</h1>
            {props.paragraphs.map(paragraph => (<p key={paragraph}>{paragraph}</p>))}
            <a href={props.link} target='_blank' css={styles.modal.learnMoreLink}>Learn More</a>
          </div>
        </Modal>
      )
    }
  </>;
};

export default Membership;
