import * as React from 'react';
import { Routes } from '../../routes';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/logoWhite.svg';
import { css } from '@emotion/core';


const styles = {
  root:     css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #f8f5f0;
    text-align: center;
    text-decoration: none;
    transition: all .25s ease;
    
    &:hover { 
      opacity: .8;
    }
  `,
  logo:     css`
    width: 200px;
    height: 200px;
  `,
  title:    css`
    font-size: 28px;
    font-weight: 600;
    padding: 0;
    margin: 0;
    text-rendering: optimizelegibility;box-sizing: border-box;
  `,
  subtitle: css`
    color: #888888;
    font-size: 16px;
    font-weight: 600;
    padding: 0;
    margin: 0;
    text-rendering: optimizelegibility;box-sizing: border-box;
  `,
};

type Props = {
  className?: string;
};


/**
 * LogoHomeButton: a component that renders the TLG logo, a title and subtitle and links to the home page.
 *
 * PureComponent: true
 * Redux Connected: false
 */
const LogoHomeButton: React.FC<Props> = props => (
  <Link to={Routes.Home} css={styles.root} className={props.className}>
    <img src={logo} alt='TLG' css={styles.logo} />
    <h1 css={styles.title}>The Little Groomer</h1>
    <h2 css={styles.subtitle}>Since 1992</h2>
  </Link>
);

export default LogoHomeButton;
