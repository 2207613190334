import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Routes } from '../routes';
import ContactPage from '../contact/ContactPage';
import AboutPage from './AboutPage';
import AwardsPage from '../awards/AwardsPage';
import StylistsPage from '../stylists/StylistsPage';
import HomePage from '../home/HomePage';


type Props = {
  className?: string;
};


/**
 * AppRouter: a component that
 *
 * PureComponent: true
 * Redux Connected: false
 */
const AppRouter: React.FC<Props> = props => (
  <Switch>
    <Route path={Routes.Contact} component={ContactPage} />
    <Route path={Routes.About} component={AboutPage} />
    <Route path={Routes.Awards} component={AwardsPage} />
    <Route path={Routes.Stylists} component={StylistsPage} />
    <Route path={Routes.Home} component={HomePage} />
  </Switch>
);

export default AppRouter;
