import * as React from 'react';
import { css } from '@emotion/core';


const styles = {
  root: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #f8f5f0;
    padding: 15px 20px;
    color: #3e3f3a;
  `,
};


type Props = {
  className?: string;
};


/**
 * PageFooter: a component that
 *
 * PureComponent: true
 * Redux Connected: false
 */
const PageFooter: React.FC<Props> = props => (
  <footer css={styles.root} className={props.className}>
    <span>© 2015 The Little Groomer Inc. All Rights Reserved.</span>
  </footer>
);

export default PageFooter;
