import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import AppRouter from './navigation/AppRouter';
import { BrowserRouter } from 'react-router-dom';
import PageHeader from './navigation/pageHeader/PageHeader';
import { PAGE_CONTENT } from './globalStyles';
import PageFooter from './navigation/PageFooter';
import { Global, css } from '@emotion/core';


const styles = {
  global:      css`
    #root {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      height: 100vh;
      overflow: auto;
    }
  `,
  pageContent: [
    PAGE_CONTENT,
    css`
      flex-grow: 1;
    `,
  ],
};


ReactDOM.render(
  <React.StrictMode>
    <Global styles={styles.global} />
    <BrowserRouter>
      <PageHeader />
      <main css={styles.pageContent}>
        <AppRouter />
      </main>
      <PageFooter />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root'),
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
